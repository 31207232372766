@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';


.GameTileFilmStrip {
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  align-items: center;

  > *:not(:last-child) {
    margin-right: $gameTileGap;
  }

  &--includeOuterPadding {
    > * {
      &:first-child {
        margin-left: $gameTileGap;
      }

      &:last-child {
        margin-right: $gameTileGap;
      }
  
      &:only-child {
        margin-left: auto;
        margin-right: auto;
      }
  
      &:first-child:nth-last-child(2) {
        margin-left: auto;
  
        & + * {
          margin-right: auto;
        }
      }
    }
  }
}
  