@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

$mobileTileWidth: 147px;
$mobileTileHeight: 96px;
$mobileTileMargin: 10px;

$desktopTileWidth: 180px;
$desktopTileHeight: 117px;
$desktopTileMargin: 16px;

.GameTileMasonry__Col {
  display: flex;
  flex-direction: column;

  @media (min-width: 1366px) {
    &:nth-child(n+3) {
      display: none;
    }
  }

  @media (min-width: 1920px) {
    &:nth-child(3),
    &:nth-child(4) {
      display: block;
    }
  }

  @media (min-width: 1920px) and (max-width: 2559px) {
    &:nth-child(4) {
      .AgGameTile:last-child {
        display: none;
      }
    }
  }

  @media (min-width: 2560px) {
    &:nth-child(5) {
      display: block;
    }
  }
}

.GameTileMasonry__Row {
  display: flex;

  @media (min-width: 1366px) {
    &:last-child {
      display: none;
    }
  }

  @media (min-width: 1920px) {
    &:last-child {
      display: flex;
    }
  }
}

.GameTileMasonry {
  display: flex;
  width: 100%;
  height: 100%;
  margin: -($mobileTileMargin * 0.5);

  .AgGameTile {
    display: block;
    width: $mobileTileWidth;
    height: $mobileTileHeight;
    box-shadow: 0 0 0 1px gray inset;
    margin: $mobileTileMargin * 0.5;

    &.--size2x {
      width: $mobileTileWidth * 2 + $mobileTileMargin;
      height: $mobileTileHeight * 2 + $mobileTileMargin;
    }
  }

  @media (min-width: 1024px) {
    margin: -($desktopTileMargin * 0.5);

    .AgGameTile {
      width: $desktopTileWidth;
      height: $desktopTileHeight;
      margin: $desktopTileMargin * 0.5;
  
      &.--size2x {
        width: $desktopTileWidth * 2 + $desktopTileMargin;
        height: $desktopTileHeight * 2 + $desktopTileMargin;
      }
    }
  }

  // 1366
  @media (min-width: 1366px) {
    width: 784px;
    height: 266px;
  }

  // 1920
  @media (min-width: 1920px) {
    width: 1372px;
    height: 399px;
  }

  // 1440
  @media (min-width: 2560px) {
    width: 1960px;
    height: 399px;
  }
}
