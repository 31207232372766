@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.DeveloperPod {
  overflow: hidden;
  font-size: 90%;
  position: relative;
  height: 285px;

  @media screen and (min-width: 750px) {
    width: 705px;
  }

  @media screen and (max-width: 749px) {
    width: calc(100svw - #{$spacing});
    max-width: 485px;

    > .Flex:first-child {
      flex: 1;
      position: relative;

      > span {
        width: 100% !important;

        img {
          margin: auto;
          object-fit: cover;
        }
      }
    }

    > .Flex:last-child {
      display: none;
    }

    .Button--medium {
      > span {
        flex-direction: column;
      }

      small {
        display: none;
        font-size: 85%;
        margin-top: -$marginSm0;
        opacity: 0.85;
      }
    }
  }

  .Button--medium {
    @media screen and (max-width: 749px) {
      height: auto;
    }

    small {
      display: none;

      @media screen and (max-width: 749px) {
        display: inline-flex;
      }
    }
  }

  > .Flex:first-child {
    position: relative;

    @media screen and (min-width: 750px) {
      min-width: 485px;
      min-height: 285px;
      max-width: 485px;
      max-height: 285px;
    }

    > div {
      position: absolute;
      bottom: $marginMd0;
      left: 50%;
      transform: translateX(-50%) translateY(150%);
      transition: all 0.3s ease;
    }

    .Button {
      padding-left: $marginMd2;
      padding-right: $marginMd2;
    }
  }

  > .Flex:last-child {
    padding: $marginMd0;

    hr {
      margin: $marginMd0 0;
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
}

.--current + div + div {
  .DeveloperPod {
    > .Flex:first-child {
      > div {
        transform: translateX(-50%) translateY(0);
      }
    }
  }
}