@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

$thumbnailWidth: 5.625rem;
$thumbnailHeight: 3.688rem;
$thumbnailBorderRadius: 4px;
$titleFontSize: 1rem;
$titleFontWeight: 700;
$ratingIconWidth: 0.8rem;
$ratingFontSize: 0.75rem;
$ratingFontWeight: 400;
$flagFontSize: 0.625rem;
$flagFontWeight: 700;
$flagBorderRadius: 4px;
$margin: 0.75rem;
$textMargin: 0.313rem;

.AgAutocompleteGameItem {
  display: flex;
  margin-bottom: $margin;
  padding-bottom: $margin;
  border-bottom: 1px solid #333333;
  cursor: pointer;

  &__thumbnailOuter {
    .AgAutocompleteGameItem__thumbnail {
      width: $thumbnailWidth;
      height: $thumbnailHeight;
      border-radius: $thumbnailBorderRadius;
      margin-right: $margin;
    }
  }

  &__metadata {
    .AgAutocompleteGameItem__title {
      font-size: $titleFontSize;
      font-weight: $titleFontWeight;
    }
    
    .AgAutocompleteGameItem__ratingOuter {
      .AgAutocompleteGameItem__ratingIcon {
        width: $ratingIconWidth;
        height: auto;
        margin-right: $textMargin;
      }

      .AgAutocompleteGameItem__rating {
        font-size: $ratingFontSize;
        font-weight: $ratingFontWeight;
        margin-bottom: $textMargin;
      }
    }

    .AgAutocompleteGameItem__genre {
      font-size: 0.75rem;
      font-weight: 400;
    }
  }

  &__flag {
    font-size: $flagFontSize;
    font-weight: $flagFontWeight;
    border-radius: $flagBorderRadius;
  }

  &:last-of-type {
    margin-bottom: 0;
    border-bottom: none;
  }
}