@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.UserAvatar {
  user-select: none;
  border: solid 3px;
  border-radius: 100px;
  font-size: 0;
  word-spacing: 0;

  img {
    width: calc(2.5rem - 8px);
    height: calc(2.5rem - 8px);
    border-radius: 100px;
    border: solid 1px rgba(#000, 0.4);
  }

  &--small {
    border-width: 2px;

    img {
      width: 2rem;
      height: 2rem;
    }
  }

  &--large {
    border-width: 4px;

    img {
      border-width: 2px;
      width: 5rem;
      height: 5rem;
    }
  }
}
