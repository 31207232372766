@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.AppFooter {
  padding: $marginMd2 0;
  font-size: 90%;
}

.AppFooter__Logo {
  img {
    height: 1.5rem;
  }
}

.AppFooter__Links {
  text-transform: uppercase;
  font-weight: $weightMedium;

  a {
    cursor: pointer;
  }

  @include isMobile {
    > .Flex {
      padding: $marginSm1 0;

      &:last-child {
        padding-bottom: 0;
      }
    }

    .RouterLink {
      min-height: 48px;
      display: inline-flex;
      align-items: center;
      justify-items: center;
    }
  }

  @include isDesktop {
    > .Flex {
      &:not(:last-child) {
        &::after {
          content: '';
          margin: 0 $marginSm2;
        }
      }
    }
  }
}
