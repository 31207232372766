@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.BlogPost {
  position: relative;
  width: 100%;

  h2 {
    font-size: $fontSizeMd;
  }

  .RouterLink {
    display: inline-flex;
    align-items: center;
    font-weight: bold;

    .Icon {
      height: 1rem;
      width: 1rem;
      margin-left: $marginSm2;
    }
  }

  .Content {
    width: 100%;
  }

  p:empty {
    &:after {
      content: '';
      display: inline-block;
      border-radius: 5px;
      width: 90%;
      height: 1em;
    }
  }

  h2 p:empty {
    &:after {
      width: 60%;
    }
  }
}

.BlogPost__Image {
  width: 100%;
  position: relative;

  > span {
    border-radius: 0.75rem;

    > img {
      object-fit: cover;
    }
  }

  .Spacer {
    width: 100%;
    height: auto;
  }
}
