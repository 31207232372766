@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.TakeoverContainer__Inner {
  margin: auto;
  position: relative;
  display: flex;
  z-index: 1;
  // box-shadow: 0 0 0 2px magenta;

  > * {
    margin: auto;
  }
}

.TakeoverContainer__Feature {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
}

.TakeoverContainer__Target {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  img,
  picture {
    width: 100%;
    height: 100%;
  }
}

.TakeoverContainer {
  width: 100%;
  display: flex;
  position: relative;

  // 375
  height: 585px;

  .TakeoverContainer__Feature {
    width: 375px;
  }

  .TakeoverContainer__Inner {
    height: 470px;
  }

  // 1024 (1024px wide)
  @media (min-width: 1024px) {
    height: 354px;

    .TakeoverContainer__Feature {
      width: 1024px;
    }

    .TakeoverContainer__Inner {
      height: 354px;
    }
  }

  // 1366 (1336px wide)
  @media (min-width: 1366px) {
    .TakeoverContainer__Feature {
      width: 1366px;
    }

    .TakeoverContainer__Inner {
      height: 336px;
    }
  }

  // 1920 (1920px wide)
  @media (min-width: 1920px) {
    height: 515px;

    .TakeoverContainer__Feature {
      width: 1920px;
    }

    .TakeoverContainer__Inner {
      height: 470px;
    }
  }

  // 1440 (2560px wide)
  @media (min-width: 2560px) {
    height: 520px;

    .TakeoverContainer__Feature {
      width: 2560px;
    }

    .TakeoverContainer__Inner {
      // width: 2020px;
    }
  }
}
