@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

$mobileTileWidth: 147px;
$mobileTileHeight: 96px;
$mobileTileMargin: 10px;

$desktopTileWidth: 180px;
$desktopTileHeight: 117px;
$desktopTileMargin: 16px;

.FluidGameCarousel {
  &--height-featured {
    min-height: 202px;

    @media (min-width: 1024px) {
      min-height: 383px;
    }
  }

  &--height-default {
    min-height: 202px;
    
    @media (min-width: 1024px) {
      min-height: 250px;
    }
  }

  &--height-single {
    min-height: 96px;
    
    @media (min-width: 1024px) {
      min-height: 117px;
    }
  }

  .FluidCarousel__Slide {
    &.--oneRow {
      padding-right: $mobileTileMargin;

      .GameTile {
        width: $mobileTileWidth;
        height: $mobileTileHeight;
      }

      @media (min-width: 1024px) {
        padding-right: $desktopTileMargin;

        .GameTile {
          width: $desktopTileWidth;
          height: $desktopTileHeight;
        }
      }
    }

    &.--desktop {
      padding-right: $desktopTileMargin;

      &.--single {
        .GameTile {
          width: ($desktopTileWidth * 2) + $desktopTileMargin;
          height: ($desktopTileHeight * 2) + $desktopTileMargin;
        }
      }

      &.--twoRows {
        > .Flex:first-child {
          margin-bottom: $desktopTileMargin;
        }
      }
    }

    &.--feature {
      margin-right: $mobileTileMargin;

      .GameTile {
        width: $mobileTileWidth;
        height: $mobileTileHeight;
      }

      @media (min-width: 1024px) {
        margin-right: $desktopTileMargin;

        .GameTile {
          width: $desktopTileWidth;
          height: $desktopTileHeight;
        }
      }

      > .Flex:first-child {
        margin-bottom: $mobileTileMargin;
        
        .GameTile {
          width: ($mobileTileWidth * 2) + $mobileTileMargin;
          height: ($mobileTileHeight * 2) + $mobileTileMargin;
        }

        @media (min-width: 1024px) {
          margin-bottom: $desktopTileMargin;
        
          .GameTile {
            width: ($desktopTileWidth * 2) + $desktopTileMargin;
            height: ($desktopTileHeight * 2) + $desktopTileMargin;
          }
        }
      }

      > .Flex:last-child {
        .Flex:first-child {
          margin-right: $mobileTileMargin;
  
          @media (min-width: 1024px) {
            margin-right: $desktopTileMargin;
          }
        }
      }
    }

    &.--mobile {
      .GameTile {
        width: $mobileTileWidth;
        height: $mobileTileHeight;
      }

      > .Flex {
        margin-right: $mobileTileMargin;

        &:first-child {
          margin-bottom: $mobileTileMargin;
        }
      }
    }
  }
}