@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.CardCarousel {
  width: 100%;
  position: relative;
}

.CardCarousel__Controls {
  display: flex;
  position: absolute;
  top: 50%;
  left: 16px;
  right: 16px;
  z-index: 1;
  pointer-events: none;

  .Button {
    border-radius: 100px;
    width: 44px;
    height: 44px;
    transform: translateY(-50%);
    pointer-events: all;

    transition-property: all !important;
    transition-duration: 300ms !important;
    transition-timing-function: ease !important;

    @media (min-width: 1024px) {
      width: 56px;
      height: 56px;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  &.--firstSlide {
    .Button:first-child {
      transform: translateX(-50%) translateY(-50%);
      opacity: 0;
      pointer-events: none;
    }
  }
}

.CardCarousel__Wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

@keyframes cardCarouselShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.CardCarousel__Wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    pointer-events: none;
    right: 0;
    background: linear-gradient(to right, rgba(23, 23, 23, 0), rgba(23, 23, 23, 0.7), rgba(23, 23, 23, 1));
  }

  &.--shake__next,
  &.--shake__prev {
    transition: none;
    animation: 1s ease-in-out 1 cardCarouselShake;
  }
}

.CardCarousel__Inner {
  height: 100%;
  min-height: 285px;
  max-height: 285px;

  &.--shake {
    animation: 0.3s linear 1 cardCarouselShake;
  }
}

.CardCarousel__Card {
  width: 0;
  min-height: 285px;
  max-height: 285px;
  left: 0%;
  top: 0;
  position: absolute;
  opacity: 0;
  z-index: 0;
  transform: scale(0.8);
  filter: brightness(0.6);
  transition-property: transform filter left opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.5s ease;
    background: #333;
    overflow: hidden;
    border-radius: $radius;
    height: 285px;

    @media screen and (min-width: 750px) {
      width: 485px;
    }
  }

  &.--current {
    display: block;
    opacity: 1;
    left: 16.7%;
    transform: scale(0.8);
    z-index: 1;
    filter: brightness(0.6);

    + div,
    + div + div,
    + div + div + div,
    + div + div + div + div {
      display: block;
      opacity: 1;
    }

    + div {
      left: 27%;
      z-index: 2;
      transform: scale(0.9);
      filter: brightness(0.8);
    }

    + div + div {
      left: 50%;
      z-index: 3;
      transform: scale(1);
      filter: brightness(1);

      > div {
        @media screen and (min-width: 750px) {
          width: 705px;
        }
      }
    }

    + div + div ~ div {
      > div {
        @media screen and (min-width: 750px) {
          width: 485px;
        }
      }
    }

    + div + div + div {
      left: 73%;
      z-index: 2;
      transform: scale(0.9);
      filter: brightness(0.8);
    }

    + div + div + div + div {
      left: 83.3%;
      z-index: 1;
      transform: scale(0.8);
      filter: brightness(0.6);
    }

    + div + div + div + div ~ div {
      left: 100%;
      z-index: 0;
      transform: scale(0.8);
      filter: brightness(0.6);
      opacity: 0;
    }
  }

  &--placeholder {
    visibility: hidden;
  }
}
