@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.GameTileContainer {
  margin: 0 auto;

  @include gameTileGridBreakpoints();

  &--withAdGutter {
    // exclude enough tiles to leave horizontal space for a 320px (20rem) ad
    $excludeTiles: ceil(math.div(20rem, $gameTileWidth + $gameTileGap));

    @include gameTileGridBreakpoints($excludeTiles, $excludeTiles + 1);
  }

  &--alignLeft {
    margin-left: 0;
    margin-right: 0;
  }
}
