@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

$margin: 0.75rem;
$textSpacing: 0.313rem;

.AgAutocompleteTagItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: $margin;
  padding-bottom: $margin;
  border-bottom: 1px solid #333333;
  cursor: pointer;

  &__nameOuter {
    .AgAutocompleteTagItem__nameLabel {
      margin-right: $margin;
      font-size: 1rem;
      font-weight: 700rem;
    }
    
    .AgAutocompleteTagItem__name {
      border-radius: 4px;
      background: #333333;
      vertical-align: middle;
      padding: $textSpacing;
    }
  }

  &__games {
    font-size: 1rem;
    font-weight: 700rem;
  }
}