@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.BlockPod {
  &--box {
    border-radius: $radius;
    padding: $spacing * 0.5;

    @media (min-width: 1024px) {
      padding: $spacing;
    }
  }

  &--fill {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    > div {
      flex: 1;
    }
  }

  &--centerHeader {
    > header {
      justify-content: center; 
      text-align: center;

      > div {
        padding-right: 0;
      }
    }
  }
}

.BlockPod > header {
  display: flex;
  align-items: center;
  line-height: 1;

  + div {
    margin-top: $spacing * 0.5;
  }
  
  @media (min-width: 1024px) {
    + div {
      margin-top: $spacing * 0.75;
    }
  }
}

// Title Slot
.BlockPod > header > div {
  font-size: $fontSizeMd;
  text-transform: uppercase;
  font-weight: $weightMedium;
  padding-right: $spacing * 0.5;
}

// Aside Slot
.BlockPod > header > aside {
  margin-left: auto;

  @include isDesktop {
    margin-left: 0;
  }
}

.BlockPod {
  &--rightAlignAside {
    > header > aside {
      margin-left: auto !important;
    }
  }
}

// Default Slot
.BlockPod > div {

}
