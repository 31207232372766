@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.AgSearch {
  input {
    border-radius: 100px;
  }

  .Autocomplete__Menu {
    margin-top: 12px;
  }

  .Autocomplete__Content {
    max-height: calc(100svh - 64px - 1rem);
  }
}