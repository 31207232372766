@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.TaxonomyTile {
  position: relative;
  display: flex;
  background-image: none;

  > span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    margin: auto;
    font-weight: 600;
    padding: 0 $marginSm3;
    line-height: 1.2;

    .IconFA {
      font-size: $fontSizeMd;
      min-width: 0.65rem;
      min-height: 0.65rem;
      max-width: 0.65rem;
      max-height: 0.65rem;
      margin-left: 0.5rem;
    }
  }

  .GameTile__Preview {
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.15;
      transform: scale(1.2);
      transition: all 1s ease;
    }
  }

  &:hover {
    .GameTile__Preview {
      img {
        opacity: 0.4;
        transform: scale(1.4);
        transition: all 0.1s ease-out;
      }
    }
  }
}