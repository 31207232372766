@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.FluidCarousel {
  width: 100%;
  position: relative;
}

.FluidCarousel__Controls {
  display: none;
  position: absolute;
  top: 50%;
  left: 16px;
  right: 16px;
  z-index: 1;
  pointer-events: none;

  @media (min-width: 500px) {
    display: flex;
  }

  .Button {
    border-radius: 100px;
    width: 44px;
    height: 44px;
    transform: translateY(-50%);
    pointer-events: all;

    transition-property: all !important;
    transition-duration: 300ms !important;
    transition-timing-function: ease !important;

    @media (min-width: 1024px) {
      width: 56px;
      height: 56px;
    }

    &:last-child {
      margin-left: auto;
    }
  }

  &.--firstSlide {
    .Button:first-child {
      transform: translateX(-50%) translateY(-50%);
      opacity: 0;
      pointer-events: none;
    }
  }
}

@keyframes fluidCarouselShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.FluidCarousel__Wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-device-width: 1024px) {
    overflow-x: hidden;
  }

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 200px;
    height: 100%;
    pointer-events: none;
    left: 0;
    z-index: 1;
    transition: 300ms opacity ease;
  }

  &::after {
    right: 0;
    left: auto;
  }

  &.--shake__next,
  &.--shake__prev {
    transition: none;
    animation: 1s ease-in-out 1 fluidCarouselShake;
  }

  &.--firstSlide {
    &::before {
      opacity: 0;
    }
  }

  @include isMobile {
    &::before,
    &::after {
      display: none;
    }
  }
}

.FluidCarousel__Inner {
  display: flex;
  height: 100%;
  transition: transform 0.3s ease;
}

.FluidCarousel__Slide {
  height: 100%;
}
