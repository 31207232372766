@use 'sass:math';
$breakpoints: (
    phoneSmall: 375px,
  phone: 450px,
  phoneLarge: 575px,
  tablet: 768px,
  desktop: 960px,
  widescreen: 1152px,
  highdef: 1344px,
  ultrahd: 1920px,

);

$gameTileWidth: 178px;
$gameTileHeight: 100px;
$gameTileGap: 8px;

@import '@/styles/_globals';

.AppHeader {
  position: inherit;

  :global(a.--active) {
    font-weight: bold;
  }

  .--disableLink {
    pointer-events: none;
  }
}

/*
 * Desktop header
 */

.DesktopHeader {
  display: none;
  padding: $marginSm3;
  position: relative;

  @include media('>=widescreen') {
    display: flex;
  }

  .Buttons {
    margin-bottom: 0 !important;

    .Button {
      margin-bottom: 0 !important;
    }
  }

  .Dropdown {
    height: 2.5rem;
    margin-left: $marginMd0;
  }
}

.DesktopHeader__Logo {
  img {
    height: 1.5rem;
  }
}

.DesktopHeader__GamepassUpgradeButton {
  line-height: 0.75;

  span {
    display: inline-flex;
    flex-direction: column;
  }
  
  small {
    font-size: 90%;
  }
}

.DesktopHeader__Dropdown {
  border-radius: 0 0 $radius $radius;
  border: solid 1px rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 100%;
  margin-top: 1px;
  z-index: 10;
  background: black;
  left: 50%;
  transform: translateX(-50%);

  .Button--large img {
    filter: none !important;
  }
}

.DesktopHeader__DropdownOverlay {
  pointer-events: none;
  opacity: 0;

  position: absolute;
  top: 65px;
  left: 0;
  width: 100%;
  max-width: 100svw;
  bottom: 0;
  z-index: 2;

  &--visible {
    pointer-events: all;
    opacity: 1;
    z-index: 10;
  }
}

.DesktopHeader__DropdownInner {
  padding: $marginMd2;

  &:last-child {
    border-radius: 0 0 $radius $radius;
    padding: $marginSm3 $marginMd2;
  }

  > div {
    margin: 0 auto;
  }

  .--secondRow .Button.Button--large {
    &:nth-child(n+4) {
      display: none;

      @media (min-width: 1350px) {
        display: inline-flex;
      }
    }
  }

  .Button.Button--large {
    height: auto;
    padding: $marginMd0;
    text-align: left;
    justify-content: flex-start;
    width: calc(33.3% - #{$marginMd0});
    min-width: 200px;

    @media (min-width: 1350px) {
      width: 200px;
    }

    img {
      width: 30px;
      height: auto;
      margin: auto;
      margin-right: $marginSm3;
      border-radius: $radiusSm;
    }

    p {
      line-height: 1;
    }

    strong {
      font-size: 1.1rem;
    }

    small {
      font-size: 0.9rem;
      font-weight: normal;
    }
  }

  ul {
    display: flex;
    opacity: 0.85;

    li {
      display: inline-block;
      font-weight: bold;

      &:not(:last-child) {
        &::after {
          content: '·';
          margin: 0 $marginMd0;
          display: inline-block;
        }
      }
    }
  }

  hr {
    margin: $spacing 0;
  }
}

/*
 * Mobile header
 */

.MobileHeader {
  display: none;
  padding: $marginSm3 0;

  @include media('<widescreen') {
    display: flex;
  }

  &--menuOpen {
    .MobileHeader__Menu__Bg {
      opacity: 1;
      pointer-events: auto !important;
    }

    .MobileHeader__Menu__Panel {
      pointer-events: all;
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    .MobileHeader__MenuButton {
      .Icon {
        transform: rotate(135deg);
      }
    }

    .MobileHeader__Menu__ExtraButtonItem {
      > .Button {
        pointer-events: auto;
      }
    }
  }
  

  &--positionTopRight,
  &--positionTopLeft {
    .MobileHeader__Menu__Panel:not(.--userPanel) {
      top: 10vh;
    }
  }
}

.MobileHeader__Logo {
  img {
    height: 1.5rem;
    margin-right: $marginMd1;
  }
}

body.--fullScreen .MobileHeader__Menu {
  z-index: $fixedZ + 1;
}

.MobileHeader__ButtonContainer {
  z-index: $fixedZ + 4;
  pointer-events: none;
  position: fixed;
  top: $marginMd1;
  left: $marginMd1;
  right: calc(2.75em + #{$marginMd1});
  bottom: calc(2.75em + #{$marginMd1});
  font-size: 150%;
}

.MobileHeader__MenuButton {
  font-size: 100%;
  z-index: $fixedZ + 4;
  width: 2.75em;
  height: 2.75em;
  padding: 0;
  box-shadow: none;
  pointer-events: auto;
  position: absolute !important;
  top: 100%;
  left: 100%;
  transition-property: top left bottom right;
  transition-duration: 200ms;
  transition-timing-function: ease;

  &--dragging {
    opacity: 0.65;
  }

  .Icon {
    transition: transform 200ms ease;
    transform: rotate(0deg);
  }

  &--TopLeft {
    top: 0;
    left: 0;
  }

  &--TopRight {
    top: 0;
    left: 100%;
  }

  &--BottomLeft {
    top: 100%;
    left: 0;
  }
}

.MobileHeader__Menu {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  z-index: 5;
  pointer-events: none;

  > .Button {
    width: 2.75em;
    height: 2.75em;
    padding: 0;
    box-shadow: none;
    position: absolute;
    bottom: $spacing;
    right: $spacing;
    z-index: 15;
    pointer-events: auto;

    .Icon {
      transition: transform 200ms ease;
      transform: rotate(0deg);
    }
  }

  .MobileHeader__Menu__Panel {
    position: absolute;
    right: 0;
    left: 0;
    max-width: 100svw;
    max-height: 100svh;
    border-radius: 0;
  }

  .AgSearch {
    .Input {
      font-size: 120%;
    }

    .Autocomplete__Menu {
      max-height: 60vh;
      overflow-y: auto;
    }
  }
}

.MobileHeader__Menu__Bg {
  content: '';
  position: absolute;
  z-index: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100svh;
  opacity: 0;
  pointer-events: none;
  transition: opacity 200ms ease;
}

.MobileHeader__Menu__Panels,
.MobileHeader__Menu__ExtraButtons {
  position: absolute;
  left: $spacing * 2;
  bottom: $spacing * 2;
  right: $spacing * 2;
  z-index: 1;
  pointer-events: none;
}

.MobileHeader__Menu__Panel {
  padding: $marginMd0;
  pointer-events: none;
  border-radius: $radius;
  transform-origin: 50% 100%;
  transition-property: opacity transform;
  transition-duration: 200ms;
  transition-timing-function: ease;
  opacity: 0;
  transform: translateY(1rem) scale(0.9);

  &--empty {
    padding: 0;
    border-radius: 0;
    background: transparent !important;
  }

  &.--userPanel {
    bottom: 5rem;
    left: 0;
    right: 0;
  }
}

.MobileHeader__Menu__ExtraButtons {
  z-index: 10;
  left: $marginMd1;
  right: $marginMd1;
  bottom: $marginMd2;
}

.MobileHeader__Menu__ExtraButtonItem {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  > .Button {
    width: 44px;
    height: 44px;
    float: right;
  }

  .MobileHeader__Menu__Panel {
    // display: none;
    position: absolute;
    bottom: 22px; // half of button width
    right: 22px;
    left: 0;
    z-index: -1;
  }
}

.MobileHeader__UserPanel__User {
  strong {
    font-size: 120%;
  }
}

.MobileHeader__DropTargets {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: 0;
  z-index: 2;
  opacity: 0;
  transition: opacity 200ms ease;
  pointer-events: none;

  &--open {
    opacity: 1;
  }

  @include media('>widescreen') {
    display: none;
  }

  > div {
    $dropTargetDiameter: 30vw;
    position: absolute;
    width: $dropTargetDiameter;
    height: $dropTargetDiameter;
    border-radius: 50%;
    z-index: 1;

    opacity: 0.65;
    transform: scale(0.65);
    transition: all 200ms ease;

    &.--active {
      opacity: 1;
      transform: scale(1);
    }
    
    &[data-position='TopLeft'] {
      top: -($dropTargetDiameter * 0.5);
      left: -($dropTargetDiameter * 0.5);
    }
    &[data-position='TopRight'] {
      top: -($dropTargetDiameter * 0.5);
      right: -($dropTargetDiameter * 0.5);
    }
    &[data-position='BottomLeft'] {
      bottom: -($dropTargetDiameter * 0.5);
      left: -($dropTargetDiameter * 0.5);
    }
    &[data-position='BottomRight'] {
      bottom: -($dropTargetDiameter * 0.5);
      right: -($dropTargetDiameter * 0.5);
    }
  }
}

.MobileHeader__MoreGamesPanel {
  border-radius: $radius;

  > .Flex:first-child {
    margin-bottom: 0;
  }

  .MobileHeader__MoreGamesPanel__Break {
    display: none;
  }

  @include media('<phoneLarge') {
    .AgGameTile {
      width: calc(50% - #{$gameTileGap});
      margin: ($gameTileGap * 0.5);
    }
  }

  @include media('>=phoneLarge') {
    .MobileHeader__MoreGamesPanel__Break {
      &:nth-child(4n) {
        display: block;
        width: 100%;
      }
    }

    > .Flex:first-child {
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    > .Flex:last-child {
      align-items: center;
      justify-content: center;
    }

    .AgGameTile {
      margin: ($gameTileGap * 0.5);
    }
  }

  @media (min-width: 800px) {
    .MobileHeader__MoreGamesPanel__Break {
      display: none !important;
    }
  }
}

.MobileHeader__Nav {
  .DropdownItem {
    align-items: center;
    display: flex;

    .IconFA {
      margin-right: $marginSm0;
    }

    span:nth-child(2) {
      font-size: 120%;
      margin-left: 4px;
    }

    &--active {
      .MobileHeader__Nav__GamepassUpgradeButton {
        filter: brightness(0) invert(1);
      }
    }
  }

  &__GamepassUpgradeButton {
    font-weight: 800;
    font-size: 120%;

    small {
      display: block;
      margin-top: -6px;
    }
  }
}

.GamePass__Banner {
  border-top: solid 1px;
  border-bottom: solid 1px;
  padding: $marginMd0;
}

.MobileHeader__ExitFullScreenBtn {
  @media (orientation: landscape) and (max-device-width: 1280px) {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}